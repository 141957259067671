<template>
    <div v-html="extra_data.renderedMarkdown"></div>
    <BoardSequence v-for="board in extra_data.boards" v-bind="board.props" />

</template>

<script setup>
import markdownit from 'markdown-it'
import {ref, nextTick, watch, reactive, onMounted,  h, render} from 'vue';
import {BoardState} from '../assets/js/board.js'
import BoardSequence from './BoardSequence.vue'

const props = defineProps({
    markdown: {
        type: String,
        default: "",
    },
    save: {
        type: Boolean,
        default: true,
    }
});

const extra_data = reactive({
    renderedMarkdown: "", 
    boards: [],
});

watch( () => props.markdown, (a, b) => {
    parse_markdown(props.markdown);
});


function is_board_component(token){
    if(token.children == null || token.children.length < 2){
        return false;
    }

    const open_token = token.children[0].content.trim();
    const close_token = token.children.at(-1).content.trim();

    if(open_token != ":::Board" || close_token != ":::"){
        return false;
    }
    return true;
}

function get_board_props(token){
    if(!is_board_component(token)){
        return {};
    }
     
}

function parse_move_parts(move_parts_str){
    const move_parts_regex = new RegExp(/(\d{1,2})\/(\d{1,2})(?:\*){0,1}(?:\((\d)\)){0,1}/, "g");
    const moves = [...move_parts_str.matchAll(move_parts_regex)];
    
    const move_data = [];
    let count;
    for(let m of moves){
        if(m.length >= 4 && m[3] != null){
            count = parseInt(m[3]);    
        }else{
            count = 1;
        }

        m = m.slice(1).map(x => parseInt(x));
        for(let i=0; i < count; i++){
            move_data.push(m.slice());
        }
    }
    return move_data;
}

function parse_moves(move_str, state){
    const move_regex = new RegExp(/(\s*\((?<color>[BW])(?<dice>[1-6]{2})\s*:\s*(?<moves>(?:\s*[0-9\/\*\(\)]+)*)\)(?<analysis>[\?\!]{0,2})\s*(?:\[(?<tooltip>.*)\]){0,1})/, "g")

    const moves = [...move_str.matchAll(move_regex)];
    const move_data = [];
    let next_state;

    for(const move of moves){
        next_state = state.copy();
        console.log(move);
        const [color, dice, move_parts_str, analysis, tooltip] = move.slice(2);
        let move_parts = parse_move_parts(move_parts_str);

        if(color == "W"){
            move_parts =  move_parts.map( (x) => [25-x[0], 25-x[1]] );
        }

        for(let m of move_parts){
            next_state = next_state.moveStoneAbs(m[0], m[1], color);
        }

        state.dice = dice.split();
        state.color = state.opponent[color];

        next_state.color = color;

        move_data.push({
            type: "move",
            data: move_parts,
            text: `(${dice}) ${move_parts_str}`,
            color: color,
            state: state,
            next_state: next_state,
            analysis: analysis,
            tooltip: tooltip,
            action_id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
        })
        state = next_state;
    }

    return move_data;
}

function replace_tags(tokens){
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];

        if(is_board_component(token)){
        
            // Replace the component tag with the rendered HTML
            const board_id = `${extra_data.boards.length}`;
            token.type = 'html_inline';
            token.tag = '';
            token.content = `<div id='slot-${board_id}'></div>`;
            
            const sequences = [];
            for(let child of token.children){
                if(child.type != "text"){
                    continue;
                }
                const content = child.content.trim();
                if(content.startsWith("state")){
                    const t = content.split(" ");
                    const state = new BoardState(t[1]);
                    sequences.push([
                        {
                            type:"state", 
                            state: state,
                            text: t.slice(2).join(" "),
                        }
                    ]);
                }
                if(content.startsWith("move")){
                    const last_state = sequences.at(-1)[0].state;
                    const moves = parse_moves(content, last_state);
                    sequences.at(-1).push(moves[0]);
                }
                if(content.startsWith("line")){
                    const last_state = sequences.at(-1)[0].state;
                    const moves = parse_moves(content, last_state);
                    console.log("LINE:", moves);
                    sequences.at(-1).push({
                        type: "line",
                        data: moves,
                    })
                }
            }

            extra_data.boards.push({
                props: {
                    boardID: board_id,
                    sequences: sequences,
                },
            });
            token.children = [];
        }

        if(token.children){
            replace_tags(token.children);
        }
    }
}

async function parse_markdown(markdown){
    extra_data.boards.length = 0;
    if(props.save){
        const save_markdown = new markdownit().disable(["image", "link"]);
        extra_data.renderedMarkdown = save_markdown.render(markdown, {});
        return;
    }
    const md = new markdownit();
    // Parse the Markdown content
    const parsedMarkdown = md.parse(markdown, {});

    // Traverse the parsed Markdown and replace Vue component tags with their rendered output
    replace_tags(parsedMarkdown);

    // Render the modified Markdown content
    const rendered_html = md.renderer.render(parsedMarkdown, {});
    await nextTick();
    
    extra_data.renderedMarkdown = rendered_html;
    await nextTick();

    for(let board of extra_data.boards){
        const board_element = document.getElementById(`boardseq-${board.props.boardID}`);
        const slot_element = document.getElementById(`slot-${board.props.boardID}`);
        if(board_element){
            slot_element.appendChild(board_element);
        }
    }
}

onMounted(async () => {
    await parse_markdown(props.markdown);
});
</script>

