<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const app_server = import.meta.env.VITE_APP_SERVER;

const extra_data = reactive({
    admin: false
});

onMounted(() => {
   check_if_admin();
});



async function check_if_admin(){
    var url = app_server + "/admin/";
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    if(response.status == 200){
        extra_data.admin = true;
    }
    console.log(response);
}

const router = useRouter();
</script>

<template>
<h1 class="text-xl font-bold mt-8 mx-4">Admin Pages</h1>
<div v-if="!extra_data.admin" class="mx-4">
These are administrator pages. Please log in as an admin. Access to these pages are
being logged by the system. Repeated unauthorized access to these pages may lead
to a ban, please be warned.
</div>
<div v-else>
<div class="flex gap-x-4 mx-4 mt-8">
<router-link :to="{name: 'admin-users'}">
    <button class="btn btn-blue">
        Users
    </button>
</router-link>

<router-link :to="{name: 'admin-matches'}">
    <button class="btn btn-blue">
        Matches
    </button>
</router-link>

<router-link :to="{name: 'admin-tournaments'}">
    <button class="btn btn-blue">
       Tournaments 
    </button>
</router-link>

<router-link :to="{name: 'admin-puzzles'}">
    <button class="btn btn-blue">
       Puzzles
    </button>
</router-link>
</div>
</div>
</template>

<style>
</style>
