<script setup>

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'

import Header from './Header.vue'
import Report from './Report.vue'
import Message from './Message.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

var match_id;


const extra_data = reactive({
    match: null,
    analysis_data: {},
    ratings: null,
    tournament_id: null,
});

onMounted(() => {
    match_id = useRoute().params.match_id;
    sseStore.connect();
    sseStore.addListener("analysis", () => getAnalysis(match_id));
    finalize_match();
    getAnalysis(match_id);
});

onUnmounted(() => {
    sseStore.addListener("analysis", () => messageStore.alertUser("Analysis ready", "The analysis for your match is ready"));
});

function replay_game(match_dict){
    const match = new Match();
    match.from_json(match_dict);
    const match_replay = new Match();
    const replay = new StateMachine(match_replay, () => {});
    
    const players = {W: match.commitments[0],
                     B: match.commitments[1]};
    var current_player = 0;
    for(let i=1; i < 10; i++){
        current_player = replay.current_color;
        match_replay.player = players[current_player];
        console.log(current_player);

        const res = replay.next_state(match.get_state(0, i-1));
        match_replay.push_state(res.board);

        console.log(i, match.get_state(0, i).toPositionString(), 
                       res.board.toPositionString(), 
                       res.board.dice.toString());
    }
}

function check_dice(match_dict){
    const match = new Match();
    match.from_json(match_dict);
    
    console.log("MATCH", match);
    const player_1 = match.commitments[0];
    const player_2 = match.commitments[1];
    if(player_1 == null || player_2 == null){
        console.log("Could not load player secrets/commitments");
        return false;
    }
    

    const s = new StateMachine()

    match.set_secret(player_1.secret, player_1);
    match.set_secret(player_2.secret, player_2);
    
    const players = {}
    players[player_1.color] = player_1;
    players[player_2.color] = player_2;
    
    
    // First check the commitments
    if(player_1.commitment != match.get_commitment(player_1)){
        console.log("Commitment player 1 not valid!");
        return false;
    }
    if(player_2.commitment != match.get_commitment(player_2)){
        console.log("Commitment player 2 not valid!");
        return false;
    }
    console.log("Commitments are OK");
    
    for(let game of match.games){
        const states = game.states_log.map(x => x[0]);
        const roll_states = states.filter(x => ["R", "PR"].includes(x.game_state));
        
        for(let i=0; i < roll_states.length; i+=2){
            const PR = roll_states[i];
            const R = roll_states[i+1];
            
            const PR_dice = match.get_dice([], PR.move_id, false, null, 
                players[PR.color] // we need the opponent
            );

            const R_dice = match.get_dice(PR_dice, R.move_id, false, null, 
                players[R.color] // we need the opponent
            );
            if(PR_dice.join("") != PR.dice.join("") || 
                R_dice.join("") !=  R.dice.join("")){
                
                console.log("States:", PR.toPositionString(), R.toPositionString())
                console.log("PR:", PR_dice.join(""), PR.dice.join(""), PR.move_id);
                console.log("R: ", R_dice.join(""), R.dice.join(""), R.move_id);
                return false;
            }
        } 
    }
    console.log("Dice are OK");
}

async function getAnalysis(match_id){
    const response = await fetch(app_server + `/match/${ match_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "config": "any",
            // "recompute": true,
        }),
    });
    const match_data= await response.json();
    
    if(match_data.status == "error"){
        console.log(match_data);
        return;
    }
    extra_data.match = match_data.match;
    extra_data.analysis_data = match_data.analysis;
    extra_data.ratings = match_data.ratings;
    console.log(match_data);
}

async function finalize_match(){
    const response = await fetch(app_server + `/match/${match_id}/finalize/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
            return null;
        }
        return response;    
    });
    if(response === null){
        extra_data.match = null;
        return;
    }
    const finalize_data = await response.json();
    console.log(finalize_data);
    extra_data.match = finalize_data.match;
    extra_data.tournament_id = finalize_data.tournament_id;
    if(finalize_data.match_ != null){
        check_dice(finalize_data.match_);
    }else{
        console.log("No match");
    }
    // replay_game(finalize_data.match_);
    
    localStorage.removeItem("current_match");
    userStore.loadUser(true); // We force reload the user so we get the latest data.
}

function get_rating_diff(){
    if(extra_data.ratings == null || extra_data.match == null){
        return {W: 0, B: 0}
    }
    if( extra_data.ratings.W == null || extra_data.ratings.W == null){
        return {
            W: 0,
            B: 0,
        }
    }
    const old_w = parseFloat(extra_data.match.white.rating);
    const old_b = parseFloat(extra_data.match.black.rating);
    
    const new_w = parseFloat(extra_data.ratings.W);
    const new_b = parseFloat(extra_data.ratings.B);

    
    return {
        W: (new_w - old_w).toFixed(1),
        B: (new_b - old_b).toFixed(1),
    }
}

function get_elo_diff(match){
    if(match.white.elo == null || match.black.elo == null){
        return {W: 0, B: 0}
    }
    const old_w = parseFloat(match.white.elo);
    const old_b = parseFloat(match.black.elo);
    
    const new_w = parseFloat(match.white.new_elo);
    const new_b = parseFloat(match.black.new_elo);
    
    return {
        W: (new_w - old_w).toFixed(2),
        B: (new_b - old_b).toFixed(2),
    }
}
function get_coins_diff(match){
    if(match.white.coins == null || match.black.coins == null){
        return {W: 0, B: 0}
    }
    const old_w = parseFloat(match.white.coins);
    const old_b = parseFloat(match.black.coins);
    
    const new_w = parseFloat(match.white.new_coins);
    const new_b = parseFloat(match.black.new_coins);
    
    return {
        W: (new_w - old_w).toFixed(2),
        B: (new_b - old_b).toFixed(2),
    }
}

function is_rating_game(){
    return extra_data.match.points >= 5;
}

</script>
<template>
    <Header />
    <Message />
    <div
        class="w-fit mx-auto my-20"
    >
    <div class="" v-if="extra_data.match == null">
        Checking the match, please wait...
    </div>
    <div class="" v-if="extra_data.analysis_data.status != 'analysed'">
        Analyzing the match, please wait...
    </div>
    <table 
            class="table-fixed mb-10 px-1 w-full md:w-fit"
            v-if="extra_data.match != null">
        <tbody>
            <tr>
                <td class="w-1/3 md:w-40 font-semibold">
                    {{ extra_data.match.white.username }}</td>
                <th class="w-1/3 md:w-60">
                    Username
                </th>
                <td class="w-1/3 md:w-40 font-semibold text-right">
                    {{ extra_data.match.black.username }}
                </td>
            </tr>
            <tr v-if="extra_data.match.match_type == 'pvp'">
                <td>
                    {{ extra_data.match.white.rating.toFixed(2) }}
                    (<span v-if="get_rating_diff().W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_rating_diff().W).toFixed(2) }})
                </td>
                <th>Rating</th>
                <td class="text-right">
                    (<span v-if="get_rating_diff().B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_rating_diff().B).toFixed(2) }})
                    {{ extra_data.match.black.rating.toFixed(2) }}
                </td>
            </tr>
            <!--
            <tr v-if="extra_data.match.white.new_elo">
                <td>{{ extra_data.match.white.new_elo.toFixed(2) }} ( 
                    <span v-if="get_elo_diff(extra_data.match).W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_elo_diff(extra_data.match).W).toFixed(2) }})
                </td>
                <th>Elo</th>
                <td class="text-right">
                    (
                    <span v-if="get_elo_diff(extra_data.match).B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_elo_diff(extra_data.match).B).toFixed(2) }})
                    {{ extra_data.match.black.new_elo.toFixed(2) }}
                </td>
            </tr>
            -->
            <tr v-if="extra_data.match.white.new_coins">
                <td>{{ extra_data.match.white.new_coins.toFixed(0) }} ( 
                    <span v-if="get_coins_diff(extra_data.match).W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_coins_diff(extra_data.match).W).toFixed(0) }})
                </td>
                <th>Sparkles</th>
                <td class="text-right">
                    (
                    <span v-if="get_coins_diff(extra_data.match).B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_coins_diff(extra_data.match).B).toFixed(0) }})
                    {{ extra_data.match.black.new_coins.toFixed(0) }}
                </td>
            </tr>
            <tr v-if="extra_data.analysis_data.status == 'analysed'">
                <td>{{ extra_data.analysis_data.analysis.match_stats.W.PR.toFixed(2)}}</td>
                <th>PR</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.PR.toFixed(2)}}
                </td>
            </tr>
            <!--
            <tr v-if="extra_data.analysis_data.status == 'analysed'">
                <td>
                    {{ extra_data.analysis_data.analysis.match_stats.W.EQ.toFixed(3)}}
                </td>
                <th>Lost Equity</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.EQ.toFixed(3)}}
                </td>
            </tr>
            <tr v-if="extra_data.match.white.expected_level && is_rating_game()">
                <td>
                    {{ extra_data.match.white.expected_level[2] }} 
                    ({{ extra_data.match.white.expected_level[0] }})
                </td>
                <th>Expected Level/PR</th>
                <td class="text-right">
                    {{ extra_data.match.black.expected_level[2] }}
                    ({{ extra_data.match.black.expected_level[0] }})
                </td>
            </tr>
            -->
            <tr v-if="extra_data.analysis_data.status == 'analysed'">
                <td>
                    {{ extra_data.analysis_data.analysis.match_stats.W.level[2] }}
                </td>
                <th>Level of Play</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.level[2] }}
                </td>
            </tr>
            <tr>
                <td class="font-semibold">{{ extra_data.match.score.W }}</td>
                <th>Score</th>
                <td class="text-right font-semibold">
                    {{ extra_data.match.score.B }}
                </td>
            </tr>
        </tbody>        
    </table>
    <div class="flex space-x-2 justify-center relative">
        <router-link :to="{name: 'analysis', param: {match_id: match_id}}">
            <button class="btn btn-blue" :disabled="extra_data.analysis_data.status != 'analysed'">
                Analysis
            </button>
        </router-link>
        <router-link v-if="extra_data.match && extra_data.match.match_type == 'bot'" 
                    :to="{name: 'bot', param: {}}">
            <button class="btn btn-blue">
                Bot List 
            </button>
        </router-link>
        <router-link v-else :to="{name: 'home', param: {}}">
            <button class="btn btn-blue">
                Match List 
            </button>
        </router-link>
        <router-link  v-if="extra_data.tournament_id != null" 
                      :to="{name: 'tournament-info', params: {tournament_id: extra_data.tournament_id}}" 
                    >
            <button class="btn btn-blue">
                Tournament Info 
            </button>
        </router-link>
        <router-link  v-if="extra_data.match && extra_data.match.match_type == 'bot'" 
                      :to="{name: 'bot-match', params: {bot_id: extra_data.match.black.user_id}}" 
                    >
            <button class="btn btn-blue">
                Play Again
            </button>
        </router-link>
        <Report :object_type="'match'" :object_id="match_id"/>
    </div> 
    </div> 
</template>

<style>
</style>
