<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CalendarIcon, CalculatorIcon, PuzzlePieceIcon, BoltIcon, AcademicCapIcon, ChatBubbleLeftRightIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import Header from './Header.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const options = [
    {
        "name": "Puzzle",
        "description": "Solve puzzles to test your skills and scale the leaderboard",
        "icon": PuzzlePieceIcon,
        "router_to": () => router.push({name:'puzzle', params:{'puzzle_id': 'random'}}),
    },
    {
        "name": "Rush",
        "description": "Solve as many puzzles as you can in 3 minutes. Beware, if you lose more than 200 millipoints of equity you are out.",
        "icon": BoltIcon,
        "router_to": () => router.push({name: 'quiz', params:{quiz_id: 'rush'}}),
    },
    {
        "name": "Quiz",
        "description": "Want to practice your weak spots? This is the place. This quiz contains puzzles of your level in the categories you make most mistakes.",
        "icon": AcademicCapIcon,
        "router_to": create_quiz_for_user,
    },
    {
        "name": "Explanations",
        "description": "Here you can browse the most recent explanations added to puzzles.",
        "icon": ChatBubbleLeftRightIcon,
        "router_to": () => router.push({name: 'puzzle-comments'}),
    },
    {
        "name": "Daily",
        "description": "Solve a new puzzle each day.",
        "icon": CalendarIcon,
        "router_to": () => router.push({name:'daily-puzzle'}),
    },
    {
        "name": "Pip-Rush (Coming Soon)",
        "description": "Are you preparing for live play and you forgot that there is no pip counter on a real tournament board? Then, this is the place to be to practice you pip counting.",
        "icon": CalculatorIcon,
        "router_to": () => router.push({name: 'quiz', params:{quiz_id: 'pip'}}),
    },
];

const extra_data = reactive({
    selected_choice: options[0],
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

async function create_quiz_for_user(){
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "user",
            quiz_user_id: userStore.info.user_id, 
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        console.log("SUCCESS!!!!");
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
    

onMounted(() => {
});


</script>

<template>
<div class="flex flex-col">
    <Header />
     
    <div class="container mx-auto max-w-xl flex flex-col items-center gap-8">
        <div class="mx-auto w-96 bg-case-med-color text-stone-w-color flex flex-col items-center gap-y-4 p-4 rounded-lg relative"
        >
            <h1 class="text-4xl font-bold"></h1>
            <div class="flex flex-col items-center gap-y-4">
                <component :is="extra_data.selected_choice.icon" 
                    class="w-36 h-36 overflow-hidden">
                </component>

                <p class="text-xl font-semibold">
                    {{ extra_data.selected_choice.name}}</p>
            </div>
            <p class="text-center min-h-[5em]">
                {{ extra_data.selected_choice.description }}
            </p>
            <button class="btn btn-blue h-12 w-1/3 text-2xl"
                @click="extra_data.selected_choice.router_to()"
                :disabled="extra_data.selected_choice.disabled"
            >
                Play
            </button>
        </div>
        <div class="grid grid-cols-3 gap-8"
        >
            <div class="w-24 h-24 bg-case-light-color shadow-lg p-2 rounded-lg ring-offset-2 ring-field-color overflow-hidden relative cursor-pointer"
                 :class="{ring: option.name == extra_data.selected_choice.name}"
                v-for="option in options"
                @click="extra_data.selected_choice = option"
            >
                <div class="flex flex-col items-center gap-y-4 text-field-color">
                    <component :is="option.icon" 
                        class="w-16 h-16">
                    </component>
                </div>
                <p class="font-thin text-center">
                    {{ option.name}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<style>
</style>

