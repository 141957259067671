<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    offset: 0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const status_options = [];

const analysis_list = reactive([]);
onMounted(() => {
    load_analysis_list();
});

async function load_analysis_list(){
    var url = app_server + "/admin/analysis/";
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    analysis_list.length = 0;// empty the users list

    if(data.analysis_list){
        analysis_list.push(...data.analysis_list);
    }
    console.log(data);
}

async function remove_analysis(analysis_id){
    return;
    const response = await fetch(app_server + `/admin/analysis/${analysis_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_analysis_list();
}

async function clear_analysis_queue(){
    const response = await fetch(app_server + `/admin/analysis/clear/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    load_analysis_list();
}

function next_page(){
    extra_data.offset += 20;
    load_analysis_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_analysis_list();
}

function get_RTT(analysis){
    const time_schedule = analysis.scheduled_time - analysis.create_time;
    var time_analysis = analysis.analysed_time - analysis.scheduled_time;
    var time_total = analysis.analysed_time - analysis.create_time;
    
    if(time_total < 0){
        time_total = "-";
    }
    if(time_analysis < 0){
        time_analysis = "-";
    }

    return [time_schedule, time_analysis, time_total];
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<div class="pl-6" v-if="!extra_data.show_create">
<select v-model="extra_data.status" @change="load_analysis_list">
    <option :value="v" v-for="v in status_options"> {{ v }}</option>
</select>
<button class="btn btn-blue" @click="clear_analysis_queue">
    Clear Analysis Queue
</button>


<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <!--<th scope="col" class="sticky top-0 z-10">ID</th> -->
        <th scope="col" class="sticky top-0 z-10">Match ID</th> 
        <th scope="col" class="sticky top-0 z-10">Config</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Priority</th> 
        <th scope="col" class="sticky top-0 z-10">Engine</th> 
        <th scope="col" class="sticky top-0 z-10">Create Time</th> 
        <th scope="col" class="sticky top-0 z-10">Scheduled Time</th> 
        <th scope="col" class="sticky top-0 z-10">Analysed Time</th> 
        <th scope="col" class="sticky top-0 z-10">RTT</th> 
        <th scope="col" class="sticky top-0 z-10">Log</th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="analysis_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Bots
    </td>
    </tr>
    <tr v-for="analysis, index in analysis_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <!--
        <td class="text-left">
            {{ analysis.analysis_id }}
        </td>
        -->
        <td class="text-left">{{ analysis.match_id }}</td>
        <td class="text-left">{{ analysis.config }}</td>
        <td class="text-left">{{ analysis.status }}</td>
        <td class="text-left">{{ analysis.priority }}</td>
        <td class="text-left">{{ analysis.engine_id }}</td>
        <td class="text-center">{{ new Date(analysis.create_time*1000).toISOString().split(".")[0] }}</td>
        <td class="text-center">{{ new Date(analysis.scheduled_time*1000).toISOString().split(".")[0] }}</td>
        <td class="text-center">
            <span v-if="analysis.analysed_time > 0">
            {{ new Date(analysis.analysed_time*1000).toISOString().split(".")[0] }}
            </span>
        </td>
        <td class="text-right">{{ get_RTT(analysis).join("/") }}</td>
        <td class="text-right">{{ analysis.log }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
