<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { storeToRefs } from 'pinia'

import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const messages = computed(() => messageStore.getMessages);

</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 z-50 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div>
        <div v-for="message in messages" :key="message.id"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4" v-if="message.show">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3 flex-grow flex-1 flex flex-col pt-0.5">
                <p class="text-lg font-medium text-gray-900">
                    {{ message.title }}
                </p>
                <p class="text-sm font-thin text-gray-900">
                    {{ message.text }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="messageStore.removeMessage(message.id)" 
                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </transition>
    </div>
  </div>
</template>

