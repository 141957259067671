<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon} from '@heroicons/vue/24/solid'

import UserTag from './UserTag.vue'

const router = useRouter();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const props = defineProps({
    user: { // Used if multiple boards are shown in the same view
        type: Object,
        default: null,
    },
});

const user_data = reactive({
    username : "",
    tag: "",
});

watch(props, () => {
    if(props.user == null){
        return
    }else{
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
    }

});

onMounted(async () => {
    if(props.user == null){
        await userStore.loadUser();
        user_data.username = userStore.info.username;
        user_data.tag = userStore.info.tag;
    }else{
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
    }
});

</script>
<template>
    <div class="flex items-center gap-x-1">
        {{ user_data.username }} <UserTag :tag="user_data.tag" />
    </div>
</template>

<style>
</style>

