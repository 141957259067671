<script setup>

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { SparklesIcon, FireIcon, ClockIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'
import Username from './UserName.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const play_server = import.meta.env.VITE_WEBSOCKET_SERVER;
const router = useRouter();

const match_list = reactive([]);

const props = defineProps({
    matches : {
        type: Array,
        default: null,
    },
    matchlist_url: {
        type: String,
        default: "/match/list/",
    },
    tournament_id: {
        type: String,
        default: "",
    },
});

const extra_data = reactive({
});

onMounted(() => {
    if(props.matches == null){
        reload();

    }else{
        match_list.push(...props.matches);
    }
});

document.onvisibilitychange = () => {
  if (document.visibilityState === "visible") {
      reload();
  }
};

const emit = defineEmits([
    'join-request',
]);


onUnmounted(() => {
});

async function reload(){
    sseStore.connect();
    if(await userStore.loadUser()){
        reload_match_list();
        add_sse_listeners();
    }else{
        // we could not load the user, log out and redirect to the homepage
        messageStore.alertUser("User not logged in", "Please log in to create and join matches");
        userStore.logout();
        setTimeout(() => router.push({name:"frontpage"}), 2000);
    }
}

function is_match_in_progress(){
    return localStorage.getItem("current_match") !== null;
}

function add_sse_listeners(){
    sseStore.addListener("match new",(data) => {
        console.log("NEW", data);
        if(props.tournament_id.length > 0 && props.tournament_id != data.tournament_id){
            console.log(props.tournament_id, data);
            return;
        }
        const match_index = match_list.findIndex((m) => m.match_id == data.match_id);
        if(match_index == -1){
            data.match.can_join = !(data.match.black && data.match.white);
            if (typeof data.match.score === 'string' || data.match.score instanceof String){
                data.match.score = JSON.parse(data.match.score);
            }
            match_list.unshift(data.match);
        }
    });
        
    sseStore.addListener("match delete", (data) => {    
        // Remove the match to the match list
        const match_index = match_list.findIndex((m) => (m.match_id == data.match_id))   ;
        if(match_index >= 0){
            match_list.splice(match_index, 1);
        }else{
            console.error(`Could not find ${data.match_id}`);
        }
    });

    sseStore.addListener("match update", (data) => {
        // Update the match to the match list
        const match_index = match_list.findIndex((m) => m.match_id == data.match_id)   ;
        console.log(data);
        if(match_index >= 0){
            match_list[match_index] = data.match;
        }else{
            console.error(`Could not update ${data.match_id}`);
        }
    });
}

async function reload_match_list(){
    /*
        Loads the match list from the server. The match list consists of the
        games that are searching for players (active) and that are currently 
        being played (playing).
    */
    extra_data.rratingeload_match_counter = 0; 
    const get_parameters = `?status=active&status=playing&status=finished`
    const response = await fetch(app_server + props.matchlist_url + get_parameters,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        console.error("ERROR while loading match list", error);
        return error;
    });
    
    if(response instanceof Error){
        messageStore.alertUser("Error Loading", 
            "The match list could not be loaded. Please try again later.");
        return;
    }
    
    const data = await response.json();
    console.log(data);

    if(data.matches){
        match_list.length = 0; // empty the match list
        match_list.push(...data.matches);
        
        for(const i in match_list){
            const match = match_list[i];
            match.can_join = !(match.black && match.white);
            if (typeof match.score === 'string' || match.score instanceof String){
                match.score = JSON.parse(match.score);
            }
        }
    }
    sseStore.connect(); 
}

function play_disabled(match){
    if((match.white && match.white.user_id == userStore.info.user_id) || (match.black && match.black.user_id == userStore.info.user_id)){
        return true;
    }
    
    if( match.points == 0 && Math.ceil(userStore.info.coins / 16) < match.stake){
        return true;
    }
    return false;
}

function to_timecontrol_string(match){
    let control;
    if(match.points == 0){
        control = time_controls.unlimited;
    }else{
        control = time_controls.match;
    }
    let timecontrol = control.find((x) => x[1] == match.time_control);

    if(timecontrol == null){
        timecontrol = [match.time_control, match.time_control];
    }
    return timecontrol; 
}

</script>
<template>
<div class="w-full grid grid-cols-1 md:grid-cols-1 gap-y-4 place-items-center">
    <button 
        class="btn btn-blue relative my-2 w-1/3"
        v-show="sseStore.connected == false && props.matches == null"
        @click="reload()"
    >
        Reconnect
    </button>
    <div v-if="match_list.length == 0"
        class="w-full text-center"
    >
        Currently there are no matches.
    </div>
    <div class="match-list grid w-full rounded-sm py-4 px-2 lg:px-8 shadow-lg relative"
         :class="{
            'bg-field-light-color': ['active', 'playing'].includes(match.status)
         }"
         v-for="match in match_list"
    >
        <div class="flex items-center grow">
            <div class="flex-col flex gap-y-2 truncate">
                <Username 
                    :user="match.white"
                    class="text-base md:text-lg font-semibold gap-x-1 truncate"
                    :class="{'text-lg': match.white.username.length < 5, 
                             'text-sm': match.white.username.length >= 7}"
                />
                <span v-if="match.points > 0" class="flex align-center">
                    <FireIcon class="w-6 h-6"/> {{ match.white.rating.toFixed(0) }}
                </span>
                <span v-else class="flex align-center">
                    <SparklesIcon class="w-6 h-6"/> {{ match.white.coins }}
                </span>
                <span class="flex align-center" v-if="match.status == 'finished'">
                    ER: {{ match.white.er.toFixed(2) }}
                </span>
            </div>
        </div>

        <div class="flex-col flex whitespace-no-wrap justify-center items-center gap-y-2">
            <span class="" v-if="match.points == 0">
                Unlimited: 
                <SparklesIcon class="w-6 h-6 inline-block"/>
                {{ match.stake}}
            </span>
            <span class="" v-else>
                {{ match.points }} Point
            </span>
            <Tooltip :text="time_control_to_text(match.time_control)"
            >
                <span class="flex align-center whitespace-no-wrap">
                    <ClockIcon class="w-6 h-6"/> {{ to_timecontrol_string(match)[0] }}
                </span>
            </Tooltip>
            <span>
                <router-link 
                        v-if="match.status == 'finished'"
                        :to="{name: 'analysis', params:{match_id: match.match_id}}">
                    <button class="btn btn-blue">Analysis</button>
                </router-link>
                <router-link 
                        v-else-if="match.status == 'playing' && match.white.user_id != userStore.info.user_id && match.black.user_id != userStore.info.user_id"
                        :to="{name: 'spectate', params:{match_id: match.match_id}}">
                    <button class="btn btn-blue">Spectate</button>
                </router-link>
                <router-link v-else-if="match.status == 'playing'"
                        :to="{name: 'match', params:{match_id: match.match_id}}">
                    <button class="btn btn-blue">Reconnect</button>
                </router-link>
            </span>
            <div class="flex items-center w-full">
                <div class="text-2xl text-left"
                     :class="{
                         'font-thin': match.score.W <= match.score.B, 
                         'font-semibold': match.score.B > match.score.W
                     }"
                >
                    {{ match.score.W }}
                </div>
                <div class="grow text-center">-</div>
                <div class="text-2xl text-right"
                     :class="{
                         'font-thin': match.score.B <= match.score.W, 
                         'font-semibold': match.score.B > match.score.B
                     }"
                >
                    {{ match.score.B }}
                </div>
            </div>
        </div>

        <div class="flex items-center justify-end grow"
            v-if="match.black"
        >
            <div class="flex-col flex gap-y-2 truncate justify-end">
                <Username 
                    :user="match.black"
                    class="text-base md:text-lg font-semibold gap-x-1 truncate"
                    :class="{'text-lg': match.white.username.length < 5, 
                             'text-sm': match.white.username.length >= 7}"
                />
                <span v-if="match.points > 0" class="flex justify-end">
                     {{ match.black.rating.toFixed(0) }} <FireIcon class="w-6 h-6"/>
                </span>
                <span v-else class="flex justify-end">
                     {{ match.black.coins }} <SparklesIcon class="w-6 h-6"/>
                </span>
                <span class="flex align-center justify-end" v-if="match.status == 'finished'">
                    ER: {{ match.black.er.toFixed(2) }}
                </span>
            </div>
        </div>
        <div class="flex-col flex gap-y-2 justify-center"
            v-else >
            <div class="flex justify-center items-center">
                <button class="btn btn-blue p-4 h-12 w-1/2"
                        v-if="match.can_join"
                        @click="matchStore.join_request(match.match_id)"
                        :disabled="play_disabled(match)"
                >
                   Play 
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.match-list{
    grid-template-columns: 1fr min-content 1fr;
}

</style>
