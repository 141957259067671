import { defineStore } from 'pinia'
import { SSE } from 'ssejs'

import {useUserStore } from '@/stores/userstore.js'
import {useMessageStore } from '@/stores/messagestore.js'


const EventSource = SSE;

const app_server = import.meta.env.VITE_APP_SERVER;

const generate_channel_options = (channel) => { return {
    state: () => { return {
            listeners : {},
            connected: 0,
            consecutive_pokes: 0,
            max_consecutive_pokes: 500,
            connection_retries: 0,
            last_heartbeat: 0,
            last_id: null,
            eventsource: null,
            channel: channel,
            users_online: 0,
        };
    },

    actions:{
        addListener(action, callback){
            // console.debug("Adding callback", action);
            this.listeners[action] = callback; 
        },

        async connect(){
            const messageStore = useMessageStore();
            const userStore = useUserStore();
            await userStore.loadUser();

            if(this.channel == "user" || this.channel == userStore.info.user_id){
                this.addListener("ping", (data) => {
                    const messageStore = useMessageStore();
                    messageStore.alertUser(data.title, data.text);
                });

                this.channel = `${userStore.info.user_id}`;
            }

            if(this.connected > 0){
                return;
            }

            if(!userStore.authenticated){
                console.log("User not authenticated");
                return;
            }
            
            console.log("Setting up SSE connection", this.channel);

            this.consecutive_pokes = 0;
            this.eventsource = new EventSource(app_server + `/stream/${this.channel}/`,{
                headers:{
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            this.last_heartbeat = Date.now();
            this.connected = 1;

            this.eventsource.onopen = () => {
                console.debug("SSE connection established");
                // Set to a connected state so we won't connect again
                this.connected = 2;
                this.connection_retries = 0;
                this.consecutive_pokes = 0;
            };

            this.eventsource.onmessage = message => {
                const data = JSON.parse(message.data);
                this.last_id = message.lastEventId;

                this.last_heartbeat = Date.now();

                if(data.online != null){
                    this.users_online = data.online;
                }
                if(data.type == "POKE"){
                    this.consecutive_pokes++;
                    if(this.consecutive_pokes >= this.max_consecutive_pokes){
                        console.log("Connection idle, lets wait for something to happen", this.consecutive_pokes);
                        messageStore.alertUser("Disconnected", "please refresh to reconnect", {time_to_live: null});
                        this.eventsource.close();
                        this.eventsource = null;
                        this.connected = 0;
                    }
                    return;
                }else{
                    this.consecutive_pokes = 0;
                }
                
                if(data.type in this.listeners){
                    this.listeners[data.type](data);
                }else{
                    console.error("No listener for message type", data);
                }
            };

            this.eventsource.onerror = (e) => {
                console.log("Event source error", e);
                this.connected = 0;
                this.consecutive_pokes = 0;
                this.disconnect();
                
                if(this.connection_retries < 10){
                    if(this.connection_retries > 0){
                        messageStore.alertUser("Reconnecting", 
                            "We lost connection with the server, reconnecting");
                    }
                    setTimeout(() => this.connect(), (this.connection_retries ** 2) * 1000);
                    this.connection_retries += 1;
                }else{
                    messageStore.alertUser("Disconnected", 
                        "An error occured, please refresh the browser to reconnect", 
                        {timeToLive: null}
                    );
                }
            }
            console.log("SSE CHANNEL", this.channel);
        },
        disconnect(){
            this.connected = 0;
            if(this.eventsource && this.eventsource.readyState < 2){
                this.eventsource.close();
            }
        }
    }
}
}

export const useSSEStore = defineStore('infostore', generate_channel_options("user"));
export const useMatchSSEStore = defineStore('matchstore', generate_channel_options("match"));
