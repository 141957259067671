<script setup>
import { FireIcon, FlagIcon, SparklesIcon, ChatBubbleLeftIcon, ChatBubbleLeftEllipsisIcon} from '@heroicons/vue/24/solid'

import Avatar from './Avatar.vue'
import UserName from './UserName.vue'
import ChatWindow from './ChatWindow.vue'

import {ref, nextTick, computed, reactive, onMounted, onUnmounted} from 'vue'

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

const props = defineProps({
    player_color: {
        type: String,
    },
    match_info: {
        type: Object,
    },
    chat_data: {
        type: Object,
        default: {lobbies: []},
    },
    show_chat: {
        type: Boolean,
        default: true,
    }
});

const extra_data = reactive({
    open: true,
});

const chat_data = reactive({
    message: {},
    show: null,
    status: "",
    lobbies: [],
    flex: false,
});


function get_user_info(opponent=false){
    if(Object.keys(props.match_info).length == 0){
        return {};
    }
    if(opponent){
        if(props.player_color == "W"){
            return props.match_info.black;
        }else{
            return props.match_info.white;
        }
    }else{
        if(props.player_color == "W"){
            return props.match_info.white;
        }else{
            return props.match_info.black;
        }
    }
    return {};
}

function get_coins_diff(opponent=false){
    // TODO fix this
    return 0;
}

function toggleChat(){
    if(chat_data.show == null){
        chat_data.show = true;
    }else{
        chat_data.show = !chat_data.show;
    }
    console.log("TOGGLE", chat_data.show);
}


</script>

<template>
<div class="sidebar h-full flex flex-col justify-between py-8
            w-[12em] lg:w-[20em]"
>
    <div class="text-board-text-color text-center flex flex-col items-center">
        <span 
            v-if="Object.keys(get_user_info(true)).length > 0" 
            class="flex flex-col gap-y-2 w-full justify-center items-center"
        >
            <p class="truncate text-xl">
                <UserName :user="get_user_info(true)" />
            </p>
            <div class="aspect-square w-1/3">
                <Avatar :avatar_id="get_user_info(true).avatar"/>
            </div>
            <p v-if="props.match_info.points > 0" 
                class='flex items-center justify-center'>
                <FireIcon class="w-4 h-4"/> 
                {{ get_user_info(true).rating.toFixed(0) }}
            </p>
            <p v-else class='flex items-center justify-center'>
                <SparklesIcon class="w-4 h-4" /> 
                {{ get_user_info(true).coins + get_coins_diff(true) }}
            </p>
        </span>
    </div>
    <div class="flex items-center justify-center">
        <button class="btn button mb-2 w-8 h-8 lg:w-16 lg:h-16"  
                :class="{'bg-point-b-color' : chatStore.unread == 0}" 
                style="padding:0.25em" 
                @click="toggleChat()"
                @new-status="(status) => {
                    chat_data.new_message = status
                }"
                v-if="props.show_chat"
        >
            
            <ChatBubbleLeftIcon v-if="chatStore.unread == 0"
                                class=""/>
            <ChatBubbleLeftEllipsisIcon v-else
                                class="text-highlight-color animate-pulse"/>
        </button>

    </div>        
    <div class="text-board-text-color text-center flex flex-col items-center">
        <span 
            v-if="Object.keys(get_user_info(false)).length > 0" 
            class="flex flex-col gap-y-2 w-full justify-center items-center"
        >
            <p v-if="props.match_info.points > 0" 
                class='flex items-center justify-center'>
                <FireIcon class="w-4 h-4"/> 
                {{ get_user_info(false).rating.toFixed(0) }}
            </p>
            <p v-else class='flex items-center justify-center'>
                <SparklesIcon class="w-4 h-4" /> 
                {{ get_user_info(false).coins + get_coins_diff(false) }}
            </p>
            <div class="aspect-square w-1/3">
                <Avatar :avatar_id="get_user_info(false).avatar"/>
            </div>
            <p class="truncate text-xl">
                <UserName :user="get_user_info(false)" />
            </p>
        </span>
    </div>
</div>
    
<ChatWindow
    :toggle="chat_data.show"
    :lobbies="props.chat_data.lobbies"
    :flex="chat_data.flex"
><span></span>
</ChatWindow>
</template>

<style scoped>
.sidebar{
    /* color-contrast is not yet supported, which is a shame */
}
@media all and (max-aspect-ratio:12/10){
    .sidebar{
        display: none;
    }
}
.button{
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 2px solid var(--button-border-color);
}
</style>

