<script setup>
import { ref, reactive, watch, defineProps, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router'

import Tooltip from './Tooltip.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const router = useRouter();

const props = defineProps([
    'open',
    'user'
]);

const emit = defineEmits([
    'logged-out',
    'close',
]);

const preferences = reactive({
    mail: null,
})

const extra_data = reactive({
    show_importexport: false,
    importexport: "",
    push_subscriptions: [],
});

onMounted( async () => {
    await userStore.loadPreferences();
    get_push_subscriptions();
    get_mail_preferences();
});

watch( userStore.preferences, () => {
});

function enable_notifications(){
    Notification.requestPermission().then( (permission) => {
        console.log(permission);
    });
}

function has_notification_permission(){
    if("Notification" in window){
        return Notification.permission == 'granted';
    }else{
        console.log("Notification API not available.");
        return true; // If true we disable the button
    }
}

async function get_push_subscriptions(){
    const response = await fetch(app_server + `/push/subscribe/`, {
        method: "GET",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    console.log(data);
    extra_data.push_subscriptions = data.subscriptions;
}

async function remove_push_subscription(subscription){
    const response = await fetch(app_server + `/push/subscribe/remove/`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(subscription),
    });
    const data = response.json();
    if(data.status == "success"){
        extra_data.push_subscriptions = data.subscriptions;
    }

    console.log(data);
}
async function add_push_subscription(){
    const data = await messageStore.subscribe_to_push();
    if(data.status == "success"){
        extra_data.push_subscriptions = data.subscriptions;
    }
}

function get_mail_preferences(){
    preferences.mail = {...userStore.preferences.mail};
    for(let key in userStore.defaultPreferences.mail){
        if(preferences.mail[key] == null){
            preferences.mail[key] = false;
        }
    }
    console.log(preferences.mail);
}

function to_label(preference_name){
    return preference_name.replace("_", " ");
}

function save_mail_preferences(){
    for(let key in preferences.mail){
        userStore.preferences.mail[key] = preferences.mail[key];
    }
    userStore.savePreferences();
}


</script>

<template>
  <div class="relative mt-6 flex flex-col gap-y-8 px-4 space-y-4 sm:px-6 container max-w-screen-sm mx-auto">
    <!-- Your content -->
    <div class="flex gap-x-2 h-10 items-stretch">
        <button class="btn btn-blue">
            <router-link :to="{name: 'home'}">
            Home
            </router-link>
        </button>
        <button class="btn btn-blue">
            <router-link :to="{name: 'leaderboard'}">
            Leaderboards
            </router-link>
        </button>
        <button class="btn btn-blue"
                @click="userStore.logout()"> 
            Logout 
        </button>
    </div> 

    <span class="flex justify-between items-center">
        <h1 class="text-2xl font-semibold">Preferences</h1>
        <button class="btn btn-blue" @click="userStore.savePreferences()">Save</button>
    </span>


    <div class="flex flex-col gap-y-4">
    <h2 class="text-3xl">Notifications</h2>
        <span class="flex gap-x-4 items-center">
            <Tooltip :text="'Allow for in-app notifications. These will not trigger if your browser/app is inactive'">
            <button class="btn btn-blue" :disabled="has_notification_permission()"
                    @click="enable_notifications()">
                Allow Notifications
            </button>
        </Tooltip>
        </span>
        <span class="flex flex-col gap-y-4">
        <span class="flex justify-between">
            <span class="text-xl font-semibold">
                Push Subscriptions:
            </span>
            <Tooltip :text="'Subscribe to push notifications. These will trigger when the browser/app is inactive.'">
            <button class="btn btn-blue" 
                    @click="add_push_subscription()">
                Subscribe to Push Notifications
            </button>
            </Tooltip>
        </span>
        <span v-if="extra_data.push_subscriptions.length == 0">
            No subscriptions
        </span>
        <div v-for="sub in extra_data.push_subscriptions"
            class="flex justify-between items-center"
        >
            <span>{{ sub.name || 'No name' }}</span>
            <TrashIcon class="w-6 h-6" 
                @click="remove_push_subscription(sub)"
            />
        </div>
        </span>
    </div>
            
    <div v-if="userStore.preferences.board">
        <span class="flex justify-between items-center mb-3">
            <h2 class="text-3xl">Board</h2>
            <button class="btn btn-blue">
                <router-link :to="{name: 'theme'}">
                Custom Themes 
                </router-link>
            </button>
        </span>
        <span class="flex gap-x-4 justify-between items-center">
            <label class="inline-block" for="pref-direction">
                Show pip numbers
            </label>
            <select id="pref-pip_numbers"
                v-model="userStore.preferences.board.show_pip_numbers"
                @change="(e) => userStore.updatePreference('board', 'show_pip_bumbers', e.target.value)"
            >
                <option :value="true">Show</option>
                <option :value="false">Hide</option>
                <option :value="null">Default</option>
            </select>
        </span>
        <span class="flex gap-x-4 justify-between items-center">
            <label class="inline-block" for="pref-direction">
                Direction (CCW)
            </label>
            <select id="pref-direction"
                v-model="userStore.preferences.board.direction"
                @change="(e) => userStore.updatePreference('board', 'direction', e.target.value)"
            >
                <option value="">Default</option>
                <option value="CW">CW</option>
                <option value="CCW">CCW</option>
            </select>
        </span>
        <span class="flex gap-x-4 justify-between items-center">
            <label class="inline-block" for="pref-direction">
                Animation 
            </label>
            <select id="pref-direction"
                v-model="userStore.preferences.board.animation_duration"
                @change="(e) => userStore.updatePreference('board', 'animation_duration', e.target.value)"
            >
                <option value="0">Off</option>
                <option value="0.2">Fast</option>
                <option value="0.5">Normal</option>
                <option value="1.0">Slow</option>
            </select>
        </span>
    </div>
    
    <div class="flex flex-col gap-y-3">
        <h2 class="text-3xl">Mail</h2>
        <label class="flex justify-between" v-for="preference, key in preferences.mail">
            <p class="capitalize">
                {{ to_label(key) }}
            </p>
            <input type="checkbox" v-model="preferences.mail[key]"/>
        </label>
        <button class="btn btn-blue w-48" @click="save_mail_preferences()">
            Save Mail Preferences
        </button>
    </div>
  </div>
</template>
