<script setup>
import {ref, watch, reactive, onMounted} from 'vue'
import {FlagIcon} from '@heroicons/vue/24/solid'

const props = defineProps({
    clock:{
        type: Object,
        default:{},
    },
    clock_config:{
        type: Object,
        default:{"reserve": -1, "delay": -1},
    },
    clock_color:{
        type: String,
        default:"W",
    },
    player_color:{
        type: String,
        default:"W",
    },
});

const data = reactive({
    "clock": {"W": 60, "B":60, "delay":10},
    "flagged": false,
});

var last_tick = null;
var tick_interval = null;

onMounted(() => {
    data.clock = Object.assign(data.clock, props.clock);
    tick();
    tick_interval = setInterval(() => {
        tick();
    },
    100);
});

watch(() => props.clock, async (newVal) => {
    data.clock = Object.assign(data.clock, newVal);
    data.flagged = false;
    if(tick_interval == null){
        last_tick = null;
        tick_interval = setInterval(() => {
            tick();
        },
        100);
    }
});

const emit = defineEmits([
    'flag'
]);

function tick(){
    if(last_tick == null){
        last_tick = Date.now();
    }
    if(data.clock["W"] == "F" || data.clock["B"] == "F"){
        flag(); 
        return;
    }
    
    // update the clock
    const time_elapsed = (Date.now() - last_tick) / 1000; // we work in seconds

    if(data.clock.delay - time_elapsed > 0){
        data.clock.delay -= time_elapsed;
    }else{
        data.clock[props.clock_color] -= (time_elapsed - data.clock.delay); 
        data.clock.delay = 0;
    }
    
    if(data.clock[props.clock_color] <= 0 && data.clock.delay <= 0){
        data.clock[props.clock_color] = "F";
    }
    
    last_tick = Date.now();
}

function flag(){
    if(props.player_color == props.clock_color && ! data.flagged){    
        emit('flag', props.clock_color);
        data.flagged = true;
        clearInterval(tick_interval);
        tick_interval = null;
        last_tick = null;
    }
}

function get_clock(top_side=true){
    if(! data.clock){
        return 0;
    }
    if(props.player_color == "W"){
        if(top_side){
            return data.clock["B"];
        }else{
            return data.clock["W"];
        }
    }else{
        if(top_side){
            return data.clock["W"];
        }else{
            return data.clock["B"];
        }
    }
}

function get_time(seconds){
    if(seconds == "F"){
        return "FF:FF";
    }
    const minutes = Math.floor((seconds / 60)).toString().padStart(2, "0");
    const sec = Math.floor(seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${sec}`
}

</script>

<template>
    <div class="text-stone-w-color flex-col">
        <div class="px-0 md:px-2 py-0 md:py-1 text-xs md:text-xl font-mono text-center" 
             :class="{'border rounded md:border-2 md:rounded-lg': props.player_color != props.clock_color}">
            {{ get_time(get_clock(true))}}
        </div>
        <div class="font-mono text-base leading-tight md:leading-normal  md:text-4xl text-center" v-if="data.clock.delay != undefined">
            {{ data.clock.delay.toFixed(0).toString().padStart(2, '0') }}
        </div>
        <div class="px-1 md:px-2 py-0.5 md:py-1 text-xs md:text-xl font-mono text-center" 
             :class="{'border-2 rounded-lg': props.player_color == props.clock_color}">
            {{ get_time(get_clock(false)) }}
        </div>
            
    </div>
</template>

<style scoped>
.time{
    width:2em;
    font-size:2em;
    line-height:2em;
    background-color:var(--point-w-color);
    color: var(--point-b-color);
    text-align:center;
    border-radius: 0.2em;
}
</style>
