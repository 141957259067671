<script setup>
import {ref, watch, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import slugify from '@sindresorhus/slugify'

import BlogArticle from './BlogArticle.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

var slug;
var autosave_timeout_id = null;

const extra_data = reactive({
    post: {
        content: "",
    },
    show_advanced: false,
    metatags_str: "{}",
    unsaved_changes: false,
    last_save: "",
    media: [],
});

watch( () => extra_data.post, (post, prevPost) => {
    if(extra_data.last_save == JSON.stringify(post) || prevPost.slug == null || slug == "new"){
        return;
    }
    extra_data.unsaved_changes = true;
    clearTimeout(autosave_timeout_id);
    autosave_timeout_id = setTimeout(() => save_post(), 5000);
}, {deep: true});

onMounted(() => {
    slug = useRoute().params.slug;
    get_media();
    if(slug != "new"){
        get_post();
    }else{
        show_advanced: true;
        extra_data.unsaved_changes = true;
    }
});
async function save_post(){
    var url = app_server + `/admin/blog/${slug}/`;
    if(slug == "new"){
        url = app_server + `/admin/blog/`;
        if(extra_data.post.slug == null || extra_data.post.slug.length == 0){
            extra_data.post.slug = slugify(extra_data.post.title);
        }
    }
    console.log("Saving...", url);

    extra_data.post.meta_tags = JSON.parse(extra_data.metatags_str);
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(extra_data.post),
    });
    
    const blog_data = await response.json();
    if(blog_data.post != null){
        extra_data.post = blog_data.post;
        extra_data.last_save = JSON.stringify(extra_data.post);
    }
    if(slug == "new"){
        slug = extra_data.post.slug;
        router.replace({name: "admin-blog-detail", params: {"slug": slug}});
    }
    slug = extra_data.post.slug;
    extra_data.unsaved_changes = false;

    console.log(blog_data);
}

async function get_post(){
    var url = app_server + `/admin/blog/${slug}/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const blog_data = await response.json();
    console.log(blog_data);
    extra_data.post = blog_data.post;
    extra_data.metatags_str = JSON.stringify(extra_data.post.meta_tags, null, 2);
    console.log(blog_data);
}

async function get_media(){
    var url = app_server + `/admin/blog/${slug}/media/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const media_data = await response.json();

    if(media_data.status == "success"){
        extra_data.media.length = 0;
        extra_data.media.push(...media_data.media);
    }
    console.log("Media", media_data);
}

async function upload_media(){
    var url = app_server + `/admin/blog/${slug}/media/`;

    const upload_input = document.getElementById('media-upload');
    const data = new FormData();
    data.append('file', upload_input.files[0])

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: data,
    });
    
    const media_data = await response.json();

    if(media_data.status == "success"){
        extra_data.media.length = 0;
        extra_data.media.push(...media_data.media);
    }
    console.log(media_data);
}

function insert_media_link(media){
    const content_field = document.getElementById('content-field');
    const link = "/media/" + media.path;
    const media_tag = `![alt-text](${link})`;

    if (content_field.selectionStart || content_field.selectionStart == '0') {
        var startPos = content_field.selectionStart;
        var endPos = content_field.selectionEnd;
        content_field.value = content_field.value.substring(0, startPos)
            + media_tag
            + content_field.value.substring(endPos, content_field.value.length);
    }

}

</script>
<template>
    <div class="grid grid-cols-2 gap-x-4 h-rest">
        <!-- COLUMN 1 -->
        <div class="flex flex-col gap-y-3 h-full pl-2">
            <div class="flex justify-between my-3">
                <h1 class="text-2xl font-semibold">Post Information</h1>
                <div class="flex gap-x-2">
                <button class="btn btn-blue" @click="extra_data.show_advanced ^= true">
                    <span v-show="extra_data.show_advanced">Hide</span>
                    <span v-show="!extra_data.show_advanced">Show</span>
                    Advanced
                </button>
                <button class="btn btn-blue" @click="save_post()" :disabled="!extra_data.unsaved_changes">
                    Save
                </button>
                </div>
            </div>
            <label class="label">
                <div>Title</div>
                <input class="input" type="text" placeholder="An awesome guide to opening moves"
                    v-model="extra_data.post.title" required>
            </label>
            <label class="label w-full" v-show="extra_data.show_advanced">
                <div>Slug 
                    <span v-if="extra_data.post.slug == null"> 
                        -- {{ slugify(extra_data.post.title || "") }}
                    </span>
                </div>
                <input class="input" type="text" placeholder="opening-moves"
                    v-model="extra_data.post.slug" required>
            </label>
            <label class="label w-full" v-show="extra_data.show_advanced">
                <div>Path</div>
                <input class="input" type="text" placeholder="/path/to/slug/"
                    v-model="extra_data.post.path" required>
            </label>
            <label class="label w-full" v-show="extra_data.show_advanced">
                <div>Status</div>
                <select class="input" placeholder="/path/to/slug/"
                    v-model="extra_data.post.status" required>
                    <option value="edit">Edit</option>
                    <option value="active">Active</option>
                    <option value="deleted">Deleted</option>
                </select>
            </label>
            <label class="label w-full" v-show="extra_data.show_advanced">
                <div>Meta Tags</div>
                <textarea class="input" type="text" placeholder="Leave empty to autogenerate."
                    v-model="extra_data.metatags_str" required></textarea>
            </label>
            <label class="label w-full">
                <div>Summary</div>
                <textarea class="input" type="text" placeholder="Do you want to know..."
                v-model="extra_data.post.summary" required></textarea>
            </label>
            <label class="label w-full grow relative block">
                <div>Content</div>
                <textarea class="input h-full" id="content-field"
                    placeholder="Every backgammon game starts with the opening..."
                v-model="extra_data.post.content" required></textarea>
            </label>
            <div class="label w-full mt-4">
                <div>Media</div>
                <div class="flex flex-row items-center gap-x-2 overflow-x-auto"
                    >
                    <div v-for="media in extra_data.media"
                         v-if="extra_data.media.length > 0"
                        class="flex flex-col w-24 h-24"
                        @click="insert_media_link(media)"
                    >
                        <img :src="'/media/' + media.path" />
                        <span>{{ media.name }}</span>
                    </div>
                    <div
                        class="flex flex-col w-32 h-16 gap-y-2  "
                    >
                        <input type="file" id="media-upload" />
                        <button class="btn btn-blue" @click="upload_media()">
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- COLUMN 1 -->
        <div class="overflow-y-auto">
            <BlogArticle :post="extra_data.post" />
        </div>
    </div>
</template>
<style scoped>
    .label{
        @apply block text-sm font-medium leading-6 text-gray-900;
    }
    .input{
        @apply w-full border-0 border-b-2 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6;
    }
</style>

