<script setup>
import {defineEmits, ref, computed, reactive, watch, onMounted} from 'vue'
import {ClockIcon, UserCircleIcon, HandThumbDownIcon, HandThumbUpIcon, SparklesIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
import MarkdownRenderer from './MarkdownRenderer.vue'

const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const min_comment_length = 90;

const props = defineProps({
    puzzle_id : {
        type: String,
        default: "",
    },
    puzzle_solved : {
        type: Boolean,
        default: false,
    },
    rated : {
        type: Number,
        default: 1,
    },
});

watch(props, (old_solved, new_solved) => {
    console.log("NEW", props, extra_data);
    if(props.puzzle_id != extra_data.puzzle_id && props.puzzle_solved == true){
        get_comments(props.puzzle_id); 
    }
});

const extra_data = reactive({
    puzzle_id: null,
    comments: [],
    edit_comment: null,
    show: true,
    sort_by: 'votes_up',
    recent_votes: null,
});

const comment_data = reactive({
    comment: "",
});

watch( () => props.puzzle_id, (puzzle_id, prevPost) => {
    retrieve_local_copy(props.puzzle_id);
});

onMounted(() => {
    userStore.loadUser();
    console.log(props.puzzle_id, extra_data.puzzle_id, props.puzzle_solved);
    if(props.puzzle_solved){
        get_comments(props.puzzle_id); 
    }
});

function save_local_copy(puzzle_id){
    localStorage.setItem(`puzzlecomment:${puzzle_id}`, comment_data.comment);
}

function retrieve_local_copy(puzzle_id){
    const comment = localStorage.getItem(`puzzlecomment:${puzzle_id}`);
    if(comment != null && comment_data.comment.length == 0){
        comment_data.comment = comment;
    }
}

function clear_local_copy(puzzle_id){
    localStorage.removeItem(`puzzlecomment:${puzzle_id}`);
}

async function get_comments(puzzle_id){
    if(!userStore.authenticated){
        return;
    }
    const response = await fetch(app_server + `/puzzle/${ puzzle_id }/comment/?rated=${ props.rated }`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    extra_data.comments = data.comments || [];
    extra_data.puzzle_id = puzzle_id;

    extra_data.comments.sort((a, b) => b.votes_up - a.votes_up);

    for(let comment of extra_data.comments){
        comment.vote = can_vote(comment);
    }
    console.log("Loading comments", data);
}

async function post_comment(puzzle_id){
    if(!confirm("Are you sure you want to post this explanation? We would like to remind you that explanations are public. Posting rude, unhelpful, or otherwise inappropriate explanations will lead to a ban.")){
        return;
    }
    var url;
    if(extra_data.edit_comment != null){
        url = `/comment/${ extra_data.edit_comment }/`;
    }else{
        url = `/puzzle/${ puzzle_id }/comment/`;
    }

    comment_data.rated = props.rated;
    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(comment_data),
    });
    const data = await response.json();
    console.log("Comment posted", data);

    if(data.status == "success"){
        messageStore.alertUser("Comment saved", "The comment has been saved successfully.");
        comment_data.comment = "";
        clear_local_copy(puzzle_id);
    }else{
        messageStore.alertUser("Error saving comment", data.message);
    }

    extra_data.edit_comment = null;
    get_comments(puzzle_id);
}

function can_vote(comment){
    if(comment.author_id == userStore.info.user_id){
        return 1;
    } 
    const recent_votes = get_recent_votes();

    return recent_votes[comment.comment_id] || 0;
}

function get_recent_votes(){
    if(extra_data.recent_votes == null){
        extra_data.recent_votes = JSON.parse(localStorage.getItem("recent_votes") || "{}");
    }

    let nrof_recent_votes = Object.keys(extra_data.recent_votes).length;
    const max_nrof_recent_votes = 1000;

    if(nrof_recent_votes > max_nrof_recent_votes){
        console.log("culling");
        // we remove half of the votes 
        const recent_votes_keys = Object.keys(extra_data.recent_votes);
        const random_indices = Array.from({length: nrof_recent_votes/2}, () => Math.floor(Math.random() * nrof_recent_votes));
        const new_recent_votes = Object.fromEntries( 
            random_indices.map((x) => [recent_votes_keys[x], recent_votes[recent_votes_keys[x]]])
        );
        extra_data.recent_votes = new_recent_votes;
    }
    return extra_data.recent_votes;

}

function update_recent_votes(comment_id, vote){
    get_recent_votes();

    extra_data.recent_votes[comment_id] = vote;
    localStorage.setItem("recent_votes", JSON.stringify(extra_data.recent_votes));
    console.log("Saved", extra_data.recent_votes);
}

async function vote_comment(comment, vote){
    if(can_vote(comment)){
        return;
    }
    const response = await fetch(app_server + `/comment/${ comment.comment_id }/vote/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({vote: vote}),
    });
    const data = await response.json();
    console.log("Vote case", data);
    if(data.status == "success"){
        update_recent_votes(comment.comment_id, vote);
    }else{

    }
}

function can_post_comment(){
    return ! extra_data.comments.map(x => x.author_id).includes(userStore.info.user_id);
}

function start_comment_edit(comment){
    extra_data.edit_comment = comment.comment_id;
    comment_data.comment = comment.comment;
}

function unsaved_changes(){
    return extra_data.edit_comment != null || comment_data.comment.length > 0;
}


</script>

<template>
    <div class="flex flex-col gap-y-4" v-if="props.puzzle_solved">
        <span class="flex justify-between mt-8 items-center">
            <h1 class="text-2xl inline-block">
                Explanations
            </h1>
            <span>
            <button class="btn btn-blue h-6" @click="extra_data.show ^= true">
                <span v-if="extra_data.show">Hide</span>
                <span v-else>Show</span>
            </button>
            </span>
        </span>
        <div v-if="extra_data.show && !userStore.authenticated">
            You need to be logged in to read the comments.

            <p class="mt-4">Please:
                <router-link :to="{name:'frontpage'}" class="text-field-color">
                    <button class="btn btn-blue">
                    Login
                    </button>
                </router-link> or 
                <router-link :to="{name:'frontpage'}" class="text-field-color">
                    <button class="btn btn-blue">
                    Register
                    </button>
                </router-link>.
            </p>
        </div>
        <div v-else-if="extra_data.show">
            <div v-if="extra_data.comments.length == 0">
                This puzzle has not yet been commented on.
            </div>
            <div class="flex flex-col border-b-2 py-2 transition-all" 
                :class="{'opacity-50 hover:opacity-100': comment.helpful < 0}"
                v-for="comment in extra_data.comments">
                <MarkdownRenderer :markdown="comment.comment" :save="true"/>
                <div v-if="comment.edit_time > 0" class="text-sm font-light italic mt-2">
                    This comment was edited on {{ new Date(comment.edit_time*1000).toDateString()}}
                </div>
                <div class="flex gap-x-4 mt-3 justify-between">
                    <div class="flex gap-x-1 items-center">
                        <SparklesIcon class="w-4 h-4 text-yellow-500" v-if="comment.helpful > 0"/>
                        <UserCircleIcon class="w-4 h-4" />
                        <span class="text-right">{{ comment.author_data.username }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(comment.create_time*1000).toDateString() }}</span>
                    </div>
                    <div v-if="comment.author_id == userStore.info.user_id">
                        <button class="btn btn-blue" @click="start_comment_edit(comment)">Edit</button>
                    </div>
                    <div class="flex gap-x-2 items-center">
                        <HandThumbDownIcon 
                            class="w-6 h-6 transition-color" 
                            :class="{
                                'text-field-color': can_vote(comment) < 0,
                                'hover:text-field-color pointer-cursor': can_vote(comment) == 0,
                            }"
                            @click="vote_comment(comment, -1)"
                        />
                        <span :class="{
                                'text-field-color': comment.votes_up > comment.votes_down,
                                'text-stone-b-color': comment.votes_up < comment.votes_down,
                            }"
                            class="font-thin"
                        >
                            <span v-if="comment.votes_up > comment.votes_down">+{{ comment.votes_up - comment.votes_down }}</span>
                            <span v-else>{{ comment.votes_up - comment.votes_down }}</span>
                        
                        </span>

                        <HandThumbUpIcon
                            class="w-6 h-6 transition-color" 
                            :class="{
                                'text-field-color': can_vote(comment) > 0,
                                'hover:text-field-color pointer-cursor': can_vote(comment) == 0,
                            }"
                            @click="vote_comment(comment, 1)"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-4 flex flex-col gap-y-2" v-if="can_post_comment() || extra_data.edit_comment != null">
                <h2 class="text-lg font-semibold" v-if="extra_data.edit_comment == null">
                    New explanation:
                </h2>
                <h2 v-else class="text-lg font-semibold">
                    Edit explanation:
                </h2>
                <textarea 
                            class="w-full h-32"
                            v-model="comment_data.comment"
                            @keyup.space="save_local_copy(props.puzzle_id)"
                ></textarea>
                <div class="flex justify-between">
                    <span v-if="comment_data.comment.length == 0">
                        Enter at least {{ min_comment_length }} characters.
                    </span>
                    <span class="tabular-nums w-32"
                        v-else-if="comment_data.comment.length < min_comment_length"
                        :class="{
                            'text-red-500': comment_data.comment.length < min_comment_length - 50,
                            'text-orange-500': comment_data.comment.length < min_comment_length && comment_data.comment.length >= min_comment_length - 50,
                            'text-field-color': comment_data.comment.length >= min_comment_length,
                        }"
                    >
                        {{min_comment_length - comment_data.comment.length}} more to go!
                    </span>
                    <span v-else>Good to go &#128077;</span>
                    <button class="btn btn-blue" 
                        @click="post_comment(props.puzzle_id)"
                        :disabled="comment_data.comment.length < min_comment_length"
                    >
                        Save Explanation
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    h1, :deep(h1){
        @apply text-xl font-semibold mt-2 mb-3
    }
    h2, :deep(h2){
        @apply text-xl font-semibold my-2
    }
    h3, :deep(h3){
        @apply text-lg font-semibold
    }
    :deep(ol){
        @apply list-decimal list-inside my-3
    }
    :deep(ul){
        @apply list-disc list-inside
    }
    :deep(blockquote){
        @apply p-4 my-4 border-s-4 border-gray-300 bg-gray-50;
    }
    :deep(p){
        @apply mb-3
    }
    :deep(a){
        @apply underline hover:text-field-color
    }
</style>
