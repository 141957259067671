/* Transition 
 * 
 *
 *
 */

import CryptoJS from 'crypto-js';
import {BoardState} from './board.js'

export class Move {
    /*
     *
     */
    constructor(board_state, timestamp=null){
        if(timestamp == null){
             
        }
    }
}

export class Game {
    /* A datastructure to keep track of the Game state
     *
     * This basically is a container for the boardstates and actions in a game
     */
    states = []; 
    states_log = [];
    move_strings = [];
    match = null;
    
    constructor(match){
        this.match = match; 
        this.states = [];
        this.states_log = [];
        this.push_state(null);
    }
    
    push_state(state, signature=null){
        if(state === null){
            return
            state = this.match.start_state;
        }
        if(this.states_log.length == 0){
            this.states_log.push([state, signature]);
        }else if(this.is_won()){
            return null;
        }else if(!this.is_possible_state(state)){
            console.log("ERROR: State not possible!");
            return null
        }
        if(["C", "D", "A", "P", "G"].includes(state.game_state)){
            this.states.push(state);
        }
        this.states_log.push([state, signature]);
    }

    next_state(){
    }

    get_state(state_id=-1){
        if(this.states_log.length == 0){
            return this.match.start_state.copy();
        }
        return this.states_log.at(state_id)[0];
    }
    
    is_resigned(){
        for(const state of this.states_log){
            if(state[0].game_state == "RG" || state[0].game_state == "RM"){
                return state[0];
            }
        }
        return false;
    }

    is_won(){
        var last_state = this.get_state();
        return last_state && ["G", "M", "F"].includes(last_state.game_state)
        /*
        if(last_state == null){
            return false;
        }
        const resigned = this.is_resigned();
        if(resigned){
            // The game was resigned, remove all stones of the winner and calculate
            // the value of the game
            if(resigned.game_state == "RG"){
                last_state = last_state.copy();
                last_state.clear_color(last_state.opponent[resigned.color]);
                last_state.update();
            }else if(resigned.game_state == "RM"){
                if(resigned.color == "W"){ // TODO resign up to the score
                    return -this.match.match_length;
                }else{
                    return this.match.match_length;
                }
            }
        }
        
        return last_state.is_won();
        */
    }

    is_crawford(){
        return false; 
    }

    is_possible_state(state){
        return true;
    }
    
    get_move_states(move_id){
        if(this.states_log.length == 0){
            console.log("STATES LOG EMPTY");
            return [];
        }
        const states = this.states_log.map((x) => x[0]); 
        const start_id = states[0].move_id;
        const end_id = states.at(-1).move_id;

        const move_states = states.filter((x) => x.move_id == (move_id + start_id));

        return move_states;
    }

    get_move(move_id){ // the move id for this game (first move of the game has id == 0
        var move_str = "";
        var dice_str = "";
        const move_states = this.get_move_states(move_id);
        const checker_state_index = move_states.findIndex((x) => x.game_state == "C");
        const dice_state_index = move_states.findIndex((x) => ["R", "IB"].includes(x.game_state));
        if(move_states.length > 2 && checker_state_index >= 0){
            // This is a checker move
            const dice_state = move_states[dice_state_index];
            const after_checker_state = move_states.at(checker_state_index);

            // dice_state.color = after_checker_state.color;
            move_str = dice_state.getMove(after_checker_state).text;
            dice_str = `${dice_state.dice[0]}${dice_state.dice[1]}`;
        }else if(move_states.length >= 1){
            console.log(move_states.map(x => x.toPositionString()));
            move_str = move_states[0].get_move(move_states[0])["text"];
        }
        
        return [dice_str, move_str];
    }
    get_move_string(move_index){
        /* Returns the move string of the move. currently this operation is
         * relatively costly, so we cache the result
         */
        return;
        if(move_index < this.move_strings.length){
            return this.move_strings[move_index]
        }
        var next_state, current_state;
        if(move_index == 0){
            current_state = this.states_log[0][0].copy();
        }else{
            current_state = this.states[move_index-1].copy();
        }
        next_state = this.states[move_index].copy();

        current_state.dice = next_state.dice;
        current_state.color = next_state.color;

        let move_str = current_state.getMove(next_state).text;
        this.move_strings.push(move_str);

        return move_str;
    }

    get_dice_string(move_id){
        return;
        if(move_id == this.states.length){
            return "";
        }
        var current_state;
        if(move_id >= this.states.length){
            current_state = null;
        }else{
            current_state = this.states[move_id];
        }

        var dice_string;
        if(current_state && current_state.dice.length > 0){
            dice_string = `${current_state.dice[0]}${current_state.dice[1]}`
        }else{
            dice_string = "" 
        }

        return dice_string;
    }
    
    get_difference(move_index){
        return [];
        var next_state, current_state;
        if(move_index == null){
            move_index = this.states.length-1;
        }
        if(move_index <= 0){
            current_state = this.states_log[0][0].copy();
            return {};
        }else{
            current_state = this.states[move_index-1].copy();
        }
        next_state = this.states[move_index].copy();

        return current_state.get_difference(next_state);
    }

    to_json(){
        return {
            states: this.states_log.map( (state) => 
                [state[0].toPositionString(), state[1]]),
        }
    }

    from_json(data){
        this.states = [];
        this.states_log = [];
        for(let state_i in data.states){
            const state = new BoardState(data.states[state_i][0]);
            const signature = data.states[state_i][1];
            this.push_state(state, signature);
        }
    }

    export_game(){
        const moves = [];
        if(this.states[0].color == "B"){
            moves.push("");
        }
        for(var state_i=0; state_i < this.states.length; state_i++){
            const [dice, move] = this.get_move(state_i);
            if(dice){
                moves.push(`${dice}: ${move}`);
            }else{
                moves.push(`${move}`);
            }
        }

        if(moves.length % 2 == 1){
            moves.push("");
        }
        const move_log = [];
        for(var i=0; i < moves.length; i += 2){
            // we pad until 6*4 (dd/dd [*4])
            const left_move = `${moves[i]}`.padEnd(6*4, " ");
            const right_move = `${moves[i+1]}`.padEnd(6*4, " ");
            move_log.push(`  ${i}) ${left_move} ${right_move}`);
        }   
        return move_log;
    }
}

export class Match {
    /* A datastructure to keep track of the Match state
     * 
     */
    games = [];
    match_id = null;
    player = {
        token: null,
        secret: null,
        color: null,
        commitment: null,
    };
    opponent = {
        token: null,
        secret: null,
        color: null,
        commitment: null,
    }
    match_length = 1;
    start_state_str = BoardState.default_position;
    start_state = null;
    commitments = [];
    move_strings = [];

    constructor(color="W", match_length=1){
        if(match_length <= 0){
            match_length = 1
        }

        this.start_state = new BoardState(this.start_state_str);
        this.start_state.match_length = match_length;

        this.player.secret = null;
        this.set_player_color(color);

        this.match_length = match_length;
        this.push_game();
    }

    set_secret(secret, player=null){
        if(player == null){
            player = this.player;
        }
        if(secret instanceof String || typeof secret == "string"){
            player.secret = CryptoJS.enc.Base64.parse(secret);
        }else{
            player.secret = secret;
        }
        return player.secret;
    }

    set_token(token, player=null){
        if(player == null){
            player = this.player;
        }
        player.token = token;
    }

    set_player_color(color){
        this.player.color = color;
        this.opponent.color = this.start_state.opponent[color];
    }

    get_secret(player=null){
        if(player == null){
            player = this.player;
        }
        if(player.secret == undefined || player.secret.length == 0){
            player.secret = CryptoJS.lib.WordArray.random(128/8);
            console.log("Choosing new secret", player.secret.toString(), this.get_commitment(player));
        } 
        if (typeof player.secret === 'string' || player.secret instanceof String){
            player.secret = CryptoJS.enc.Base64.parse(player.secret);
            console.log("Using secret", player.secret.toString(), this.get_commitment(player));
        }
        // Something weird about CryptoJS TODO figure out why this is needed...
        // player.secret = CryptoJS.enc.Base64.parse(
        // CryptoJS.enc.Base64.stringify(player.secret));

        return player.secret;
    }

    get_token(player=null){
        if(player == null){
            player = this.player;
        }
        return player.token;
    }

    push_game(){
        if(this.is_won()){
            return null;
        }
        this.games.push(new Game(this));

        return this.games.at(-1);
    }

    push_state(state, signature=null){
        let last_game = this.games.at(-1);
        state.update();

        if(this.is_won()){
            if(state.game_state == "G" || state.game_state == "M"){
                last_game.push_state(state, signature=signature);
            }
            return null ;
        }else{

            if(last_game.get_state().game_state == "G"){
                last_game = this.push_game();
            }
            
            last_game.push_state(state, signature=signature);
        }
    }

    is_force_quit(){
        const ff_states = [];

        for(const game of this.games){
            for(const state_sig of game.states_log){
                const state = state_sig[0];
                if(state.game_state == "FF"){
                    ff_states.push(state);
                }
            }
        }

        if(ff_states.length == 0){
            return false;
        }

        return ff_states[0];
    }

    is_resigned(){
        const rm_states = [];

        for(const game of this.games){
            for(const state_sig of game.states_log){
                const state = state_sig[0];
                if(state.game_state == "RM"){
                    rm_states.push(state);
                }
            }
        }

        if(rm_states.length == 0){
            return false;
        }

        return rm_states[0];
    }

    is_won(){
        if(this.games.length == 0){
            return false;
        }
        const last_state = this.get_state()
        return last_state && ["F", "M"].includes(last_state.game_state)
    }

    get_score(until_game=undefined){
        let score = {"W":0, "B":0};
        for(let game of this.games.slice(0, until_game)){
            let game_won_by = game.is_won();
            if(game_won_by > 0){
                score["W"] += game_won_by;
            }else if(game_won_by < 0){
                score["B"] += -game_won_by;
            }
        }
        
        return score;
    }

    get_game(game_id=null){
        var game;
        if(game_id === null){
            game = this.games.at(-1);
        }else{
            game = this.games.at(game_id);
        }
        return game;
    }

    get_state(game_id=-1, state_id=-1){
        return this.get_game(game_id).get_state(state_id);
    }

    get_move_string(move_id, game_id=null){
        let game = this.get_game(game_id);
        return game.get_move_string(move_id);
    }

    get_dice_string(move_id, game_id=null){
        let game = this.get_game(game_id);
        return game.get_dice_string(move_id);
    }
    
    get_move_counter(){
        return this.games.reduce(
            (partialSum, game) => partialSum + game.states_log.length, 
            0 // Initial value
        );
    }

    get_dice(dice, move_counter=null, distinct=false, key=null, player=null){
        //placeholder
        if(move_counter == null){
            move_counter = this.get_move_counter();
        }
        if(key == null){
            key = this.get_secret(player);
        }
        if(dice == null || dice.length != 2){
            dice.length = 0;
            dice.push(0);
            dice.push(0);
        }
        // console.log("DICE", dice, move_counter, distinct, key.toString());
        const new_dice = dice.slice()
        
        var hash = CryptoJS.HmacSHA256(`${move_counter}`, key);
        for(let i=0; i < 6; i++){
            const lower = Math.abs(hash.words[i] & 0xFFFF);
            const upper = Math.abs( (hash.words[i]) >> 16 & 0xFFFF);
            new_dice[0] += lower;
            if(!distinct || i < 5){
                new_dice[1] += upper;
            }
        }
        if(distinct){
            new_dice[0] = new_dice[0] % 6;
            new_dice[1] = (new_dice[0] + (new_dice[1] % 5) + 1) % 6;
        }else{
            new_dice[0] = new_dice[0] % 6;
            new_dice[1] = new_dice[1] % 6;
        }
        new_dice[0] += 1;
        new_dice[1] += 1;

        return new_dice;
    }

    sign_position(positionString, player=null){
        if(player == null){
            player = this.player;
        }
        const key = CryptoJS.enc.Utf8.parse(this.player.token);
        var hash = CryptoJS.HmacSHA256(positionString, key);

        hash = CryptoJS.enc.Base64.stringify(hash);

        return hash;
    }

    get_commitment(player=null){
        if(player == null){
            player = this.player;
        }
        let message = player.token;
        let key = this.get_secret(player);

        var hash = CryptoJS.HmacSHA256(`${message}`, key);
        hash = CryptoJS.enc.Base64.stringify(hash);
        
        return hash;
    }
    
    is_crawford(game_index=-1){
        const score = this.get_score(game_index);

        if(!(score["W"] == this.match_length-1 || score["B"] == this.match_length-1)){
            return false;
        }
        // it is match point
        if(this.games.length - game_index - 1 <= 0){
            return false;
        }
        // now check if the previous game was also match point
        const last_game_score = this.get_score(game_index-1);
        if(last_game_score["W"] == this.match_length-1 || last_game_score["B"] == this.match_length-1){
            return false; 
        }
        return true;
    }

    can_double(color=null){
        if(color == null){
            color = this.player.color;
        }
        if(this.is_crawford()){
            return false;
        }

        const score = this.get_score();
        const current_cube_value = 2**this.get_state().cube.value
        if(score[color] >= this.match_length - current_cube_value){
            return false;
        }

        const state = this.get_state();
        if(!(state.cube.owner == "N" || state.cube.owner == color)){
            return false;
        }

        if(score[0] + 2**state.cube.value >= this.match_length || 
           score[1] + 2**state.cube.value >= this.match_length){
            return false;
        }

        return true;
    }

    get_log(){
        const log = [];
        log.push(this.player);
        log.push(this.opponent);
        for(const game_index in this.games){
            const game = this.games[game_index];
            log.push(`\nGame: ${game_index}`);
            for(const state_index in game.states_log){
                const state = game.states_log[state_index];
                log.push(`${state[0].toPositionString()} ${state[1]}`);
            }
        }

        return log;
    }

    export_match(){
        var log = [];
        log.push('; [Site "OpenGammon"]');
        log.push(`; [Match ID "${this.match_id}"]`);
        log.push(`; [Player 1 "${this.player.token}"]`);
        log.push(`; [Player 2 "${this.opponent.token}"]`);
        log.push(`; [Variation "Backgammon"]`);
        log.push(`; [Crawford "On"]`);
        log.push(`; [Cubelimit "1024"]`);
        log.push(`\n${this.match_length} point match`);

        for(var game_index in this.games){
            game_index = parseInt(game_index);
            const game = this.games[game_index];
            const score = this.get_score(game_index);
            const score_text = [];
            score_text.push(` White : ${score['W']}`.padEnd(6*4, " "));
            score_text.push(`Black : ${score['B']}`.padEnd(6*4, " "));
            const game_nr = parseInt(game_index) + 1;
            log.push(`\n Game ${game_nr}`);
            log.push(score_text.join(""));
            log.push(...game.export_game());
        }
        return log;
    }

    check_log(){
        var check = true;
        // First check if the secrets are published
        if(!this.player.secret || !this.opponent.secret){
            console.log("Missing secret", this.player.secret, this.opponent.secret);
            check &= false;
        }
        // Check the commitments
        const commitment_color_map = Object.fromEntries(
            this.commitments.map(x=> [x.color, x.commitment]));

        if(this.get_commitment(this.player) != commitment_color_map[this.player.color]){
            check &= false;
        }
        if(this.get_commitment(this.opponent) != commitment_color_map[this.opponent.color]){
            check &= false;
        }
        // Check the dice rolls

        // Check the signatures on the states
        
        // Check the validity of the moves

        return check;
    }

    to_json(){
        return {
            match_id: this.match_id,
            player: {
                color: this.player.color,
                token: this.get_token(),
                secret: CryptoJS.enc.Base64.stringify(this.get_secret()),
            },
            opponent: {
                color: this.opponent.color,
                token: this.get_token(this.opponent),
                secret: CryptoJS.enc.Base64.stringify(this.get_secret(this.opponent)),
            },
            match_length: this.match_length,
            initial_state: this.start_state.toPositionString(),
            games: this.games.map(x => x.to_json()),
        }
    }

    from_json(data){
        this.match_id = data.match_id;
        this.start_state_str = data.initial_state;
        this.start_state = new BoardState(this.start_state_str);
        // Load the players
        if(data.player){
            this.set_player_color(data.player.color);
            this.set_token(data.player.token);
            this.set_secret(data.player.secret);
        }

        if(data.opponent){
            this.set_token(data.opponent.token, this.opponent);
            this.set_secret(data.opponent.secret, this.opponent);
        }

        if(data.players){// set the commitments received b
            this.commitments = data.players;
        }

        this.match_length = data.match_length;
        this.games = [];
        for(let game_data of data.games){
            let game = new Game(this);
            game.from_json(game_data);
            this.games.push(game);
        }
    }
}
