// Beginner
import {MartinBot} from './bots/martin.js'
import {LisaBot} from './bots/lisa.js'
import {TysonBot} from './bots/tyson.js'
import {BillieBot} from './bots/billie.js'
import {LolaBot} from './bots/lola.js'
// Intermediate
import {TobiasBot} from './bots/tobias.js'
import {PatrickBot} from './bots/patrick.js'
import {AlexBot} from './bots/alex.js'
//Engine
import {GnuBot} from './bots/gnubot.js'
import {BGBlitzBot} from './bots/bgblitzbot.js'
import {WildBGBot} from './bots/wildbgbot.js'

import {Match} from './match.js'
import {StateMachine} from './statemachine.js'
import {BoardState} from './board.js'

const bot_id_bot = {
    // Beginner 
    "martin-bot": MartinBot,
    "lisa-bot": LisaBot,
    "tyson-bot": TysonBot,
    "billie-bot": BillieBot,
    "lola-bot": LolaBot,
    // Intermediate 
    "tobias-bot": TobiasBot,
    "patrick-bot": PatrickBot,
    "alex-bot": AlexBot,
    // Engine
    "gnu-1ply": GnuBot,
    "bgblitz-1ply": BGBlitzBot,
    "wildbg-1ply": WildBGBot,
};


export class Bot{
    constructor(bot_id, send_message_callback, bot_config={}, delay=500){
        this.bot = new bot_id_bot[bot_id](bot_config);
        this.match = new Match("B", this.bot.match_length);
        this.match.player_color = "B";

        this.state_machine = new StateMachine();
        this.state_machine.player_color = "B";
        this.state_machine.roll_dice_callback = (dice, move_counter, distinct) => this.match.get_dice(dice, move_counter, distinct);
        this.player_event_func = (e) => this.on_message(e.detail);
        this.delay=delay;
        
        // register the message handler
        this.remove_eventlistener();
        document.addEventListener("player-message", this.player_event_func);
    }

    remove_eventlistener(){
        document.removeEventListener("player-message", this.player_event_func);
    }

    sendMessage(positionString, type="move", extra_data=null){
        // send the move to the other player
        let signature = this.match.sign_position(positionString);
        const data = {
            type: type, 
            match_id: this.match.match_id, 
            state: positionString,
            signature: signature,
            extra_data: extra_data,
        };
        this.match.push_state(new BoardState(positionString), signature);
        
        document.dispatchEvent( new CustomEvent("bot-message", {
            detail: data
        }));
    }

    sendPremoves(premove_data){
        const data = {
            type: "premove", 
            match_id: this.match.match_id, 
            premoves: premove_data,
        };
        document.dispatchEvent( new CustomEvent("bot-message", {
            detail: data
        }));
    }

    async do_bot_action(board){
        const old_board = board.copy();
        const action = await this.bot.find_move(board);
        let moves = [];
        if(typeof action == "object"){
            moves = old_board.getMove(action, true);
        }
        if(moves.data != null && Array.isArray(moves.data)){
            moves = moves.data.map( (m) => [m[0], Math.abs(m[1] - m[0]), board.opponent[board.color]], moves);
            for(let move_index in moves){
                move_index = parseInt(move_index);
                console.log("sending premoves", move_index, moves.slice(0, move_index+1), Date.now());
                this.sendPremoves(moves.slice(0, move_index+1));
                await new Promise(resolve => setTimeout(resolve, this.delay + 200));
            }
        }


        // console.log("MOVE", moves, old_board.toPositionString(), board.toPositionString());
        

        setTimeout(() => {
            // This means we need to take an action
            console.log("send move", Date.now());
            this.handleMove(board.toPositionString(), action);
        }, this.delay + 200);
    }

    async on_message(data){
        let board = new BoardState(data["state"]);
        this.match.push_state(board, data["signature"]);
        
        const new_boardstate = await this.handleMove(board.toPositionString());

        if(new_boardstate == null){
            this.sendMessage(board.toPositionString());
            await this.do_bot_action(board);
        }
    }

    async handleMove(positionString, action=null){
        const boardstate = new BoardState(positionString);
        var new_boardstate = this.state_machine.next_state(boardstate, action);     

        if(new_boardstate == null){
            return null;
        }else{
            if(new_boardstate.game_state == "IB" && new_boardstate.color != this.state_machine.player_color){
                this.sendMessage(new_boardstate.toPositionString());
                await this.do_bot_action(new_boardstate);
            }
            else if(new_boardstate.game_state == "F"){
                const extra_data = this.match.to_json().player;
                this.sendMessage(new_boardstate.toPositionString(), "final", extra_data);
            }else{
                this.sendMessage(new_boardstate.toPositionString());
            }
        }
        return new_boardstate;
    }
};
