<script setup>
import {ref, computed, reactive, defineProps, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {ChatBubbleLeftRightIcon, SparklesIcon, UserCircleIcon, PuzzlePieceIcon, 
    FireIcon, TrashIcon, CheckIcon, XMarkIcon, Bars3Icon} from '@heroicons/vue/24/solid'

import Message from './Message.vue'
import MatchAccept from './MatchAccept.vue'
import JoinTournament from './JoinTournament.vue'
import Tooltip from './Tooltip.vue'
import ChatWindow from './ChatWindow.vue'
import MatchInProgress from './MatchInProgress.vue'

const router = useRouter();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();


const props = defineProps({
});

const extra_data = reactive({
    rating_to_show : 0,
});

let interval_id = null;

onMounted(() => {
    userStore.loadUser();
    console.log("user", userStore.info);
    interval_id = setInterval( () => {
        extra_data.rating_to_show = (extra_data.rating_to_show + 1) % 4;
    }, 30000);
});

onUnmounted( () => {
    clearInterval(interval_id);
});

</script>


<template>
<Message />
<MatchAccept 
    v-if="userStore.authenticated"
/>
<MatchInProgress 
    v-if="userStore.authenticated"
/>
<JoinTournament 
    v-if="userStore.authenticated"
/>
<div class="flex flex-row-reverse p-2 top-0 h-header items-center">
    <router-link :to="{name: 'user-account'}" 
        v-if="userStore.authenticated"
        class="flex gap-x-2 items-end">
        <Bars3Icon class="h-8 cursor-pointer"/>
    </router-link>
    <ChatWindow 
        v-if="userStore.authenticated"
    />
    <div class="flex gap-x-2">
    <slot />
    </div>
    <div 
         class="grow text-lg gap-x-4 flex items-end"
    >
        <router-link :to="{name: 'home'}" class="flex gap-x-2 items-end">
            <img class="h-8 aspect-square" src="@/assets/images/logo.png">
            <span class="text-xl"
                  v-if="!userStore.info.username" 
            >
            OpenGammon
            </span>
        </router-link>

        <span class="flex items-center gap-x-1 cursor-pointer whitespace-nowrap"
              v-if="userStore.info.username" 
            >
            <router-link :to="{name: 'user-info', params:{user_id: userStore.info.user_id}}">
                <UserCircleIcon class="w-6 h-6 inline-block text-case-color"/>
                    {{ userStore.info.username }}
            </router-link>
        </span>

        <Tooltip :text="'#Users online'" :direction="'bottom'">
        <span class="flex items-center gap-x-1"
              v-if="sseStore.users_online > 0" 
        >
            <span class="rounded-full h-2 w-2 bg-field-color" />
            <span class="font-thin"> 
                {{ sseStore.users_online }} 
            </span>
        </span>
        </Tooltip>
        
        <Transition  mode="out-in"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            enter-active-class="transition duration-300 ease-in"
            leave-active-class="transition duration-300 ease-in"
            @click="extra_data.rating_to_show = (extra_data.rating_to_show + 1) % 4"
        >
        <Tooltip :text="'Your Rating'" :direction="'bottom'"
            v-if="extra_data.rating_to_show == 0"
        >
        <span class="flex items-center gap-x-1  transition-opacity"
              v-if="userStore.info.username" 
        >
            <FireIcon class="w-6 h-6 inline-block"/>
            <span v-if="userStore.info.rating"> 
                {{ userStore.info.rating.toFixed(0) }} 
            </span>
        </span>
        </Tooltip>
        
        <Tooltip :text="'Your Puzzle Rating'" :direction="'bottom'"
            v-else-if="extra_data.rating_to_show == 1"
        >
        <span class="flex items-center gap-x-1  transition-opacity"
              v-if="userStore.info.username" 
        >
            <PuzzlePieceIcon class="w-6 h-6 inline-block"/>
            <span v-if="userStore.info.puzzle_rating"> 
                {{ userStore.info.puzzle_rating.toFixed(0) }} 
            </span>
        </span>
        </Tooltip>
        
        <Tooltip :text="'Your sparkles'" :direction="'bottom'"
            v-else-if="extra_data.rating_to_show == 2"
        >
        <span class="flex items-center gap-x-1  transition-opacity"
              v-if="userStore.info.username" 
        >
            <SparklesIcon class="w-6 h-6 inline-block"/>
            <span v-if="userStore.info.coins"> 
                {{ userStore.info.coins.toFixed(0) }} 
            </span>
        </span>
        </Tooltip>

        <Tooltip :text="'Your Comment rating'" :direction="'bottom'"
            v-else-if="extra_data.rating_to_show == 3"
        >
        <span class="flex items-center gap-x-1  transition-opacity"
              v-if="userStore.info.username" 
        >
            <ChatBubbleLeftRightIcon class="w-6 h-6 inline-block"/>
            <span v-if="userStore.info.comment_rating"> 
                {{ userStore.info.comment_rating.toFixed(0) }} 
            </span>
        </span>
        </Tooltip>
        </Transition>
        <!--
        <span v-if="userStore.info.rating" class="hidden md:inline"> 
            - {{ userStore.info.rating.toFixed(2) }} 
        </span>
        <span v-if="userStore.info.level" class="hidden md:inline"> 
            - ({{ userStore.info.level.at(2) }})
        </span>
        -->
    </div>
</div>
</template>
